import PropTypes from 'prop-types';
import { FC } from 'react';
import { SKU } from '../../types/sku';
import { ETemplateType } from '../../templates/types';
import { SKUEditDetailsDialog as SKUEditDetailsDialogDefault } from '../../templates/default/components/sku/sku-edit-details-dialog';
import { SKUEditDetailsDialog as SKUEditDetailsDialogVera } from '../../templates/vera-admin/components/sku/sku-edit-details-dialog';

interface SKUDuplicateDialogProps {
  open: boolean;
  onClose: () => void;
  sku: SKU;
}

export const SKUEditDetailsDialog: FC<SKUDuplicateDialogProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <SKUEditDetailsDialogDefault {...props} />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <SKUEditDetailsDialogVera {...props} />;
  }
};

SKUEditDetailsDialog.defaultProps = {
  open: false,
};

SKUEditDetailsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sku: PropTypes.any,
};
