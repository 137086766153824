import { FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { Task } from '../../types/job';
import { getTaskTitle, isEventRunnable } from '../../utils/jobs-helper';
import { PropertyListItem } from '../property-list-item';
import { ItemTimelineListItemTasksAssets } from '../../templates/default/components/item/item-timeline-list-item-tasks-assets';
import { PropertyList } from '../property-list';
import { PropertyListItemMetadata } from '../property-list-item-metadata';
import { ItemTimelineListItemTasksImage } from '../item/item-timeline-list-item-tasks-image';

interface JobTaskProps {
  index?: number;
  task: Task;
}

export const JobTask: FC<JobTaskProps> = ({ index, task, ...other }) => {
  const runnableJob = isEventRunnable(task);

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const align = mdDown ? 'vertical' : 'horizontal';

  const showEventMetadata = useMemo(() => Object.keys(task.publicMetadata).length > 0, [task]);
  const showFile = useMemo(() => task.file?.url, [task]);

  return (
    <Box key={task.id} sx={{ mb: 2 }} {...other}>
      {Number.isFinite(index) && (
        <CardContent>
          <Typography color="textPrimary" variant="subtitle1">
            {index + 1}. {getTaskTitle(task)} Task
          </Typography>
        </CardContent>
      )}
      <PropertyList>
        <PropertyListItem align={align} label="Task ID" value={task.id} />
        <PropertyListItem align={align} label="Result" value={task.resultMessage} />
        {runnableJob && (
          <>
            <PropertyListItem align={align} label="Time" value={Number.isFinite(task.time) ? `${(task.time / 1000).toFixed(2)} s` : '-'} />
            <PropertyListItem align={align} label="Status" value={task.status} />
            <PropertyListItem align={align} label="Worker Queue" value={task.workerQueue} />
            <PropertyListItem align={align} label="Worker ID" value={task.workerId} />
          </>
        )}
        {showFile && (
          <PropertyListItem align={align} label="File">
            <ItemTimelineListItemTasksImage task={task} />
          </PropertyListItem>
        )}
        {showEventMetadata && (
          <PropertyListItemMetadata
            label="Metadata"
            metadata={task.publicMetadata}
            mlLogFilename={`task-${task.id}-ml-log`}
          />
        )}
        {task.assets?.length > 0 && (
          <PropertyListItem align={align} label="Task Assets">
            <ItemTimelineListItemTasksAssets task={task} />
          </PropertyListItem>
        )}
      </PropertyList>
    </Box>
  );
};

JobTask.propTypes = {
  index: PropTypes.number,
  // @ts-ignore
  task: PropTypes.object.isRequired,
};
