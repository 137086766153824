import { Box, Button, Divider, ListItemProps, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { Task } from '../../../../types/job';
import { Download as DownloadIcon } from '../../../../icons/download';

interface ItemTimelineListItemTasksAssetsProps extends ListItemProps {
  task: Task;
}

export const ItemTimelineListItemTasksAssets: FC<ItemTimelineListItemTasksAssetsProps> = ({
  task,
}) => {
  if (task.assets?.length === 0) {
    return <></>;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {task.assets.map((asset, index, array) => (
        <Box
          key={asset.id}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              title={asset.file?.title}
              sx={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {asset.file?.title}
            </Typography>
            {asset.title !== asset.file?.title && (
              <Typography variant="body2" color="textSecondary" title={asset.title}>
                {asset.title}
              </Typography>
            )}
          </Box>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            href={asset.file?.url}
            target="_blank"
          >
            Download
          </Button>
          {index !== array.length - 1 && <Divider sx={{ gridColumn: '1 / 3' }} />}
        </Box>
      ))}
    </Box>
  );
};

ItemTimelineListItemTasksAssets.propTypes = {
  // @ts-ignore
  task: PropTypes.object.isRequired,
};
