import { FC, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { useDialog } from '../../../../hooks/use-dialog';
import { ImageThumbnail } from '../../../../components/image-thumbnail';
import { ImageDialog } from '../../../../components/image-dialog';
import { Item } from '../../../../types/item';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';
import { getAssetUrl, getOverviewUrl } from '../../../../utils/assets-helper';
import { EAssetTitle } from '../../../../types/asset';

interface ShownImage {
  title: string;
  src: string;
}

interface ItemAssetsCardProps {
  item: Item;
  location?: 'event' | 'other';
}

export const ItemAssetsCard: FC<ItemAssetsCardProps> = ({ item, ...other }) => {
  const [shownImage, setShownImage] = useState<ShownImage>();

  const [openImage, handleOpenImage, handleCloseImage] = useDialog();

  const handleShowImage = (image: ShownImage) => {
    setShownImage(image);
    handleOpenImage();
  };

  const displayUnavailable = useMemo(() => !item.assets || item.assets.length === 0, [item.assets]);

  return (
    <>
      <Card variant="outlined" {...other}>
        <CardHeader title="Item Images" />
        <Divider />
        <CardContent>
          {displayUnavailable ? (
            <ResourceUnavailable
              text="No images available at this moment."
              sx={{
                flexGrow: 1,
              }}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  Overview
                </Typography>
                <ImageThumbnail
                  alt="item overview"
                  src={getOverviewUrl(item)}
                  onClick={() => {
                    handleShowImage({
                      title: `Overview Image`,
                      src: getOverviewUrl(item),
                    });
                  }}
                />
              </Grid>
              {getAssetUrl(item, EAssetTitle.FP1) && (
                <Grid item md={3} sm={4} xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    Fingerprint 1
                  </Typography>
                  <ImageThumbnail
                    alt="item fp1"
                    src={getAssetUrl(item, EAssetTitle.FP1)}
                    onClick={() => {
                      handleShowImage({
                        title: `Fingerprint 1`,
                        src: getAssetUrl(item, EAssetTitle.FP1),
                      });
                    }}
                  />
                </Grid>
              )}
              {getAssetUrl(item, EAssetTitle.FP2) && (
                <Grid item md={3} sm={4} xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    Fingerprint 2
                  </Typography>
                  <ImageThumbnail
                    alt="item fp2"
                    src={getAssetUrl(item, EAssetTitle.FP2)}
                    onClick={() => {
                      handleShowImage({
                        title: `Fingerprint 2`,
                        src: getAssetUrl(item, EAssetTitle.FP2),
                      });
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
      <ImageDialog
        open={openImage}
        onClose={handleCloseImage}
        title={shownImage?.title}
        src={shownImage?.src}
        maxWidth="lg"
      />
    </>
  );
};

ItemAssetsCard.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
};
