import { FC, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import ImageNotSupportedIcon from '@material-ui/icons/ImageNotSupported';

import { Eye as EyeIcon } from '../icons/eye';

type ImageThumbnailSize = 'auto' | 'default';

interface ImageThumbnailProps {
  src: string;
  alt?: string;
  onClick?: () => void;
  size?: ImageThumbnailSize;
  sx?: SxProps;
}

export const ImageThumbnail: FC<ImageThumbnailProps> = ({ src, alt, onClick, size, ...other }) => {
  const hasImage = Boolean(src);

  const handleClick = useCallback(() => {
    if (hasImage) {
      onClick();
    }
  }, [hasImage, onClick]);

  const hoverStyles = hasImage && {
    '&:hover': {
      boxShadow: (theme) => `0px 0px 0px 1px ${theme.palette.primary.main}`,
      '&::before': {
        display: 'block',
      },
      '& > .MuiBox-root': {
        display: 'inline-flex',
      },
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: size === 'auto' ? 'auto' : '140px',
        height: size === 'auto' ? 'auto' : '140px',
        ...other?.sx,
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          boxShadow: '0 0 1px #000000',
          backgroundColor: 'divider',
          alignItems: 'center',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer',
          '&::before': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: 1,
            bottom: 0,
            content: '""',
            display: 'none',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          },
          ...hoverStyles,
          img: {
            maxWidth: '100%',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            overflow: 'hidden',
          },
        }}
      >
        {hasImage ? (
          <>
            <img alt={alt} src={src} />
            <Box
              color="primary"
              sx={{
                color: 'text.secondary',
                display: 'none',
                margin: 'auto',
                position: 'absolute',
              }}
            >
              <EyeIcon />
            </Box>
          </>
        ) : (
          <Box
            color="primary"
            sx={{
              color: 'text.secondary',
              margin: 'auto',
            }}
          >
            <ImageNotSupportedIcon fontSize="large" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

ImageThumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.any,
};
