import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { LoadingButton } from '@material-ui/lab';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { SKU, SKUMetadata } from '../../../../types/sku';
import { useAuth } from '../../../../hooks/use-auth';
import { useAxios } from '../../../../hooks/use-axios';
import { useUploader, UseUploaderProps } from '../../../../hooks/use-uploader';
import logger from '../../../../utils/logger';
import { EAssetTitle } from '../../../../types/asset';
import { AssetType } from '../../../../utils/uppy-S3-helper';
import { ResponseData } from '../../../../types/axios';
import { FingerprintLocation } from '../../../../types/fingerprint';
import { calculateFingerprintLocation } from '../../../../utils/fingerprint-helper';

interface SKUEditAssetsDialogProps {
  open: boolean;
  onClose: () => void;
  sku: SKU;
}

interface EditSKUAssetsFormValues {
  overviewFileId: string;
  skuFPFileId: string;
}

export const RESET_VALUES = {
  overviewFileId: '',
  skuFPFileId: '',
  submit: null,
};

export const SKUEditAssetsDialog: FC<SKUEditAssetsDialogProps> = ({
  open,
  onClose,
  sku,
  ...other
}) => {
  const { axios } = useAxios();
  const { tenant } = useAuth();
  const queryClient = useQueryClient();

  const overviewAsset = useMemo(
    () =>
      sku?.assets?.find(
        (asset) => asset.title === EAssetTitle.OVERVIEW || asset.title === EAssetTitle.overview,
      ),
    [sku],
  );
  const skuFPAsset = useMemo(
    () => sku?.assets?.find((asset) => asset.title === EAssetTitle.FINGERPRINT),
    [sku],
  );
  const [isOverviewAssetSet, setIsOverviewAssetSet] = useState(overviewAsset !== undefined);
  const [isSkuFPAssetSet, setIsSkuFPAssetSet] = useState(skuFPAsset !== undefined);

  const overviewUploaderOptions = useMemo(
    (): UseUploaderProps => ({
      uploaderObject: {
        id: sku?.id,
      },
      customUploaderProps: {
        accept: ['image/*'],
        title: 'Supported formats: .jpg, .png',
        shownFile: overviewAsset && {
          src: overviewAsset.file.url,
          type: 'image',
        },
        flexGrow: true,
        onFileRemoved: () => setIsOverviewAssetSet(false),
      },
      generateOverview: false,
    }),
    [overviewAsset],
  );

  const {
    upload: overviewUpload,
    isLoading: isOverviewUploaderLoading,
    error: overviewUploaderError,
    uppy: overviewUppy,
    uploaderProps: overviewUploaderProps,
    Uploader: WalletUploader,
  } = useUploader(overviewUploaderOptions);

  const skuFPUploaderOptions = useMemo(
    (): UseUploaderProps => ({
      uploaderObject: {
        id: sku?.id,
      },
      customUploaderProps: {
        accept: ['image/*'],
        title: 'Supported formats: .jpg, .png',
        shownFile: skuFPAsset && {
          src: skuFPAsset.file.url,
          type: 'image',
        },
        flexGrow: true,
        onFileRemoved: () => setIsSkuFPAssetSet(false),
      },
      generateOverview: false,
      generateThumbnail: false,
      returnImageDimensions: true,
    }),
    [skuFPAsset],
  );

  const {
    upload: skuFPUpload,
    isLoading: isSkuFPUploaderLoading,
    error: skuFPUploaderError,
    uppy: skuFPUppy,
    uploaderProps: skuFPUploaderProps,
    Uploader: SkuFPUploader,
  } = useUploader(skuFPUploaderOptions);

  const removeAssetMutation = useMutation(async (assetId: string) => {
    const url = `/skus/${sku.id}/assets/${assetId}`;
    return axios.delete<ResponseData<any>>(url);
  });

  const updateMutation = useMutation(
    async ({ overviewFileId, skuFPFileId }: EditSKUAssetsFormValues) => {
      if (!tenant) {
        throw new Error('Tenant is missing');
      }

      const data = {
        tenantId: tenant.id,
        assets: [],
      };

      // Remove assets if needed
      const assetsToRemove = [];
      if (!overviewFileId && overviewAsset?.file.id) {
        assetsToRemove.push(overviewAsset.id);
      }
      if (!skuFPFileId && skuFPAsset?.file.id) {
        assetsToRemove.push(skuFPAsset.id);
      }
      await Promise.all(assetsToRemove.map((assetId) => removeAssetMutation.mutateAsync(assetId)));

      // Upload assets if needed
      const [overviewUploadResult, skuFPUploadResult] = await Promise.all([
        overviewFileId && overviewFileId !== overviewAsset?.file.id
          ? overviewUpload()
          : Promise.resolve(),
        skuFPFileId && skuFPFileId !== skuFPAsset?.file.id ? skuFPUpload() : Promise.resolve(),
      ]);

      // Overview upload result
      if (overviewUploadResult) {
        const overviewFile = overviewUploadResult.successful.find(
          (f) => f.meta.assetType === AssetType.ORIGINAL,
        )?.meta;
        const overviewThumbnailFile = overviewUploadResult.successful.find(
          (f) => f.meta.assetType === AssetType.THUMBNAIL,
        )?.meta;

        data.assets = [
          {
            title: EAssetTitle.overview,
            fileId: overviewFile.remoteFileId,
            uploadTime: overviewFile.uploadTime,
            forceCreate: true,
          },
          {
            title: EAssetTitle.thumbnail,
            fileId: overviewThumbnailFile.remoteFileId,
            uploadTime: overviewThumbnailFile.uploadTime,
            forceCreate: true,
          },
        ];
      }

      // SKU Fingerprint upload result
      if (skuFPUploadResult) {
        const skuFPFile = skuFPUploadResult.successful.find(
          (f) => f.meta.assetType === AssetType.ORIGINAL,
        )?.meta;

        data.assets = [
          ...data.assets,
          {
            title: EAssetTitle.FINGERPRINT,
            fileId: skuFPFile.remoteFileId,
            uploadTime: skuFPFile.uploadTime,
            forceCreate: true,
          },
        ];

        // Update fingerprint location
        let location: FingerprintLocation = {
          x: 0,
          y: 0,
          width: 800,
          height: 1060,
        };

        if (
          Number.isFinite(skuFPFile?.width) &&
          Number.isFinite(skuFPFile?.height) &&
          Number.isFinite(Number(sku?.publicMetadata?.widthCm)) &&
          Number.isFinite(Number(sku?.publicMetadata?.heightCm))
        ) {
          location = calculateFingerprintLocation({
            imageWidthPx: Number(skuFPFile.width),
            imageHeightPx: Number(skuFPFile.height),
            realWidthCm: Number(sku.publicMetadata.widthCm),
            realHeightCm: Number(sku.publicMetadata.heightCm),
          });
        }

        const updateSKUurl = `/skus/${sku?.id}`;
        const updateSKUdata = {
          tenantId: sku?.tenantId,
          publicMetadata: {
            fingerprintLocation: location,
          } as SKUMetadata,
        };

        const updateSKUresponse = await axios.put<ResponseData<SKU>>(updateSKUurl, updateSKUdata);

        if (updateSKUresponse.status !== 200) {
          throw new Error('Failed to update SKU');
        }
      }

      if (data.assets.length === 0) {
        return Promise.resolve('No assets to update');
      }

      const url = `/skus/${sku?.id}/assets/bulk`;

      return axios.post<ResponseData<any>>(url, data);
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...RESET_VALUES,
      overviewFileId: overviewAsset?.file.id || '',
      skuFPFileId: skuFPAsset?.file.id || '',
    },
    validationSchema: Yup.object().shape({
      overviewFileId: Yup.string().optional(),
      skuFPFileId: Yup.string().optional(),
    }),
    onSubmit: async (values, helpers) => {
      const { submit, ...vals } = values;

      updateMutation.mutate(vals, {
        onSuccess: () => {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);

          toast.success('SKU images were updated');
          queryClient.invalidateQueries('sku');
          onClose();
        },
        onError: (err: AxiosError) => {
          logger('[SKU/UPDATE] Error', err);
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
          helpers.setErrors({ submit: (err?.response?.data as any)?.error?.message });
        },
      });
    },
  });

  useEffect(() => {
    overviewUppy.on('file-added', (file) => {
      setIsOverviewAssetSet(true);
      formik.setFieldValue('overviewFileId', file.id);
    });
  }, [overviewUppy]);

  useEffect(() => {
    skuFPUppy.on('file-added', (file) => {
      setIsSkuFPAssetSet(true);
      formik.setFieldValue('skuFPFileId', file.id);
    });
  }, [skuFPUppy, formik]);

  useEffect(() => {
    if (!isOverviewAssetSet) {
      formik.setFieldValue('overviewFileId', '');
    }

    if (!isSkuFPAssetSet) {
      formik.setFieldValue('skuFPFileId', '');
    }
  }, [isOverviewAssetSet, isSkuFPAssetSet]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
        },
      }}
      {...other}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit SKU Images</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {/* Wallet picture uploader */}
              <Typography variant="subtitle2">Overview Image</Typography>
              <WalletUploader {...overviewUploaderProps} />
              {Boolean(!formik.values.overviewFileId && overviewAsset?.file) && (
                <FormHelperText>You are about to remove this assets.</FormHelperText>
              )}
              {Boolean(
                (formik.touched.overviewFileId && formik.errors.overviewFileId) ||
                  overviewUploaderError,
              ) && (
                <FormHelperText error>
                  {formik.errors.overviewFileId || overviewUploaderError}
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {/* SKU Fingerprint uploader */}
              <Typography variant="subtitle2">SKU Fingerprint</Typography>
              <SkuFPUploader {...skuFPUploaderProps} />
              {Boolean(!formik.values.skuFPFileId && skuFPAsset?.file) && (
                <FormHelperText>You are about to remove this assets.</FormHelperText>
              )}
              {Boolean(
                (formik.touched.skuFPFileId && formik.errors.skuFPFileId) || skuFPUploaderError,
              ) && (
                <FormHelperText error>
                  {formik.errors.skuFPFileId || skuFPUploaderError}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              formik.isSubmitting ||
              isOverviewUploaderLoading ||
              isSkuFPUploaderLoading ||
              updateMutation.isLoading
            }
            color="primary"
            onClick={onClose}
            variant="text"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={
              formik.isSubmitting ||
              isOverviewUploaderLoading ||
              isSkuFPUploaderLoading ||
              updateMutation.isLoading
            }
            color="primary"
            type="submit"
            size="large"
            variant="contained"
          >
            Update Images
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SKUEditAssetsDialog.defaultProps = {
  open: false,
};

SKUEditAssetsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sku: PropTypes.any,
};
