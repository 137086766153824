import { FC, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { useDialog } from '../../../../hooks/use-dialog';
import { ImageThumbnail } from '../../../../components/image-thumbnail';
import { ImageDialog } from '../../../../components/image-dialog';
import { SKU } from '../../../../types/sku';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';
import { getAssetUrl, getOverviewUrl } from '../../../../utils/assets-helper';
import { EAssetTitle } from '../../../../types/asset';
import { SKUEditAssetsDialog } from './sku-edit-assets-dialog';

interface ShownImage {
  title: string;
  src: string;
}

interface SKUAssetsCardProps {
  sku: SKU;
  editable?: boolean;
  location?: 'event' | 'other';
}

export const SKUAssetsCard: FC<SKUAssetsCardProps> = ({ sku, editable = true, ...other }) => {
  const [shownImage, setShownImage] = useState<ShownImage>();
  const [open, handleOpen, handleClose] = useDialog();
  const [openImage, handleOpenImage, handleCloseImage] = useDialog();

  const handleShowImage = (image: ShownImage) => {
    setShownImage(image);
    handleOpenImage();
  };

  const displayUnavailable = useMemo(() => !sku.assets || sku.assets.length === 0, [sku.assets]);

  return (
    <>
      <Card variant="outlined" {...other}>
        <CardHeader
          action={
            editable && (
              <Button color="primary" onClick={handleOpen} variant="text">
                Edit
              </Button>
            )
          }
          title="SKU Images"
        />
        <Divider />
        <CardContent>
          {displayUnavailable ? (
            <ResourceUnavailable
              text="No images available at this moment."
              sx={{
                flexGrow: 1,
              }}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  Overview
                </Typography>
                <ImageThumbnail
                  alt="sku overview"
                  src={getOverviewUrl(sku)}
                  onClick={() => {
                    handleShowImage({
                      title: `Overview Image`,
                      src: getOverviewUrl(sku),
                    });
                  }}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  Fingerprint Area Navigation
                </Typography>
                <ImageThumbnail
                  alt="sku fp area"
                  src={getAssetUrl(sku, EAssetTitle.FP_AREA)}
                  onClick={() => {
                    handleShowImage({
                      title: `Fingerprint Area Navigation`,
                      src: getAssetUrl(sku, EAssetTitle.FP_AREA),
                    });
                  }}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <ImageDialog
        open={openImage}
        onClose={handleCloseImage}
        title={shownImage?.title}
        src={shownImage?.src}
        maxWidth="lg"
      />
      {open && <SKUEditAssetsDialog open={open} onClose={handleClose} sku={sku} />}
    </>
  );
};

SKUAssetsCard.propTypes = {
  // @ts-ignore
  sku: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};
