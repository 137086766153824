import { FC, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { EJobTitle, Job } from '../../../../types/job';
import { useAuth } from '../../../../hooks/use-auth';

interface JobsSkuCellProps {
  job: Job;
}

export const JobsSkuCell: FC<JobsSkuCellProps> = ({ job }) => {
  const { tenant } = useAuth();

  const sku = useMemo(() => job.item?.sku || job.sku, [job]);

  const jobTimelineUrl = useMemo(() => {
    switch (job.title) {
      case EJobTitle.PROTECTION:
      case EJobTitle.VERIFICATION:
        return `/tenants/${tenant?.id}/items/${job.item?.id}/timeline?source=jobs#${job.id}`;
      case EJobTitle.REGISTRATION:
      case EJobTitle.IDENTIFICATION:
      case EJobTitle.DELETE_REGISTRATION:
      case EJobTitle.CLASSIFICATION_REGISTRATION:
      case EJobTitle.CLASSIFICATION_CLASSIFICATION:
        return `/tenants/${tenant?.id}/skus/${sku?.id}/timeline?source=jobs#${job.id}`;
      default:
        return ``;
    }
  }, [tenant?.id, job, sku]);

  if (!sku) {
    return (
      <Typography variant="body2" color="inherit">
        -
      </Typography>
    );
  }

  if (!jobTimelineUrl) {
    return (
      <Typography variant="body2" color="inherit">
        {sku.title || 'N/A'}
      </Typography>
    );
  }

  return (
    <Link
      color="inherit"
      component={RouterLink}
      sx={{ display: 'block' }}
      to={jobTimelineUrl}
      underline="none"
      variant="subtitle2"
    >
      <Typography
        variant="body2"
        color="inherit"
        noWrap
        maxWidth={{
          md: 200,
          lg: 350,
          xl: 450,
        }}
        sx={{
          textDecoration: 'underline',
        }}
      >
        {sku.title || 'N/A'}
      </Typography>
    </Link>
  );
};

JobsSkuCell.propTypes = {
  job: PropTypes.any.isRequired,
};
