import { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';

import { X as XIcon } from '../icons/x';

interface ImageDialogProps extends Pick<DialogProps, 'maxWidth'> {
  open: boolean;
  onClose: () => void;
  title: string;
  src: string;
}

export const ImageDialog: FC<ImageDialogProps> = ({
  open,
  onClose,
  title,
  src,
  maxWidth = false,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={false}>
    <DialogTitle
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        gridGap: '16px',
        display: 'flex',
      }}
    >
      <Typography
        color="textPrimary"
        variant="inherit"
        sx={{
          p: 0,
        }}
      >
        {title}
      </Typography>
      <IconButton onClick={onClose} size="small">
        <XIcon fontSize="small" sx={{ color: 'text.primary' }} />
      </IconButton>
    </DialogTitle>
    <Divider />
    <DialogContent
      sx={{
        display: 'grid',
        justifyContent: 'center',
        pb: 4,
      }}
    >
      <img
        src={src}
        alt={title}
        style={{
          height: '100%',
          maxWidth: '100%',
          objectFit: 'contain',
          overflow: 'hidden',
          margin: 'auto',
        }}
      />
    </DialogContent>
  </Dialog>
);

ImageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  src: PropTypes.string,
  maxWidth: PropTypes.any,
};
