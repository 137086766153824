import { FC } from 'react';
import PropTypes from 'prop-types';
import type { SKU } from '../../types/sku';
import { ETemplateType } from '../../templates/types';
import { SKUDetailsCard as SKUDetailsCardDefault } from '../../templates/default/components/sku/sku-card-details';
import { SKUDetailsCard as SKUDetailsCardVera } from '../../templates/vera-admin/components/sku/sku-card-details';

interface SKUDetailsCardProps {
  sku: SKU;
  location?: 'details' | 'other';
}

export const SKUDetailsCard: FC<SKUDetailsCardProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <SKUDetailsCardDefault {...props} />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <SKUDetailsCardVera {...props} />;
  }
};

SKUDetailsCard.propTypes = {
  // @ts-ignore
  sku: PropTypes.object.isRequired,
  location: PropTypes.oneOf(['details', 'other']),
};
