import { FC, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { useDialog } from '../../../../hooks/use-dialog';
import { ImageThumbnail } from '../../../../components/image-thumbnail';
import { ImageDialog } from '../../../../components/image-dialog';
import { SKU } from '../../../../types/sku';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';
import { SKUEditAssetsDialog } from '../../../../components/sku/sku-edit-assets-dialog';
import { useAuth } from '../../../../hooks/use-auth';

interface ShownImage {
  title: string;
  src: string;
}

interface SKUAssetsCardProps {
  sku: SKU;
  editable?: boolean;
  location?: 'event' | 'other';
}

export const SKUAssetsCard: FC<SKUAssetsCardProps> = ({ sku, editable, location, ...other }) => {
  const [shownImage, setShownImage] = useState<ShownImage>();
  const [open, handleOpen, handleClose] = useDialog();
  const [openImage, handleOpenImage, handleCloseImage] = useDialog();
  const { tenant } = useAuth();

  const handleShowImage = (image: ShownImage) => {
    setShownImage(image);
    handleOpenImage();
  };

  const displayUnavailable = useMemo(() => !sku.assets || sku.assets.length === 0, [sku.assets]);

  const gridSizeMD = useMemo(() => (location === 'event' ? 4 : 3), [location]);
  const gridSizeSM = useMemo(() => (location === 'event' ? 6 : 4), [location]);
  const gridSizeXS = useMemo(() => (location === 'event' ? 12 : 6), [location]);

  return (
    <>
      <Card variant="outlined" {...other}>
        <CardHeader
          action={
            tenant.isActive &&
            editable && (
              <Button color="primary" onClick={handleOpen} variant="text">
                Edit
              </Button>
            )
          }
          title="SKU Images"
        />
        <Divider />
        <CardContent>
          {displayUnavailable ? (
            <ResourceUnavailable
              text="No images available at this moment."
              sx={{
                flexGrow: 1,
              }}
            />
          ) : (
            <Grid container spacing={2}>
              {sku.assets?.map((asset) => (
                <Grid item md={gridSizeMD} sm={gridSizeSM} xs={gridSizeXS} key={asset.id}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {asset.title}
                  </Typography>
                  <ImageThumbnail
                    alt={asset.title}
                    src={asset.file?.url}
                    onClick={() => {
                      handleShowImage({
                        title: `Image: ${asset.title}`,
                        src: asset.file?.url,
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </CardContent>
      </Card>
      <ImageDialog
        open={openImage}
        onClose={handleCloseImage}
        title={shownImage?.title}
        src={shownImage?.src}
        maxWidth="lg"
      />
      {open && <SKUEditAssetsDialog open={open} onClose={handleClose} sku={sku} />}
    </>
  );
};

SKUAssetsCard.propTypes = {
  // @ts-ignore
  sku: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  location: PropTypes.oneOf(['event', 'other']),
};
