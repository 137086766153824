import { FC } from 'react';
import PropTypes from 'prop-types';
import type { Item } from '../../types/item';
import { ETemplateType } from '../../templates/types';
import { ItemDetailsCard as ItemDetailsCardDefault } from '../../templates/default/components/item/item-card-details';
import { ItemDetailsCard as ItemDetailsCardAuthentique } from '../../templates/authentique/components/item/item-card-details';
import { ItemDetailsCard as ItemDetailsCardVera } from '../../templates/vera-admin/components/item/item-card-details';

interface ItemDetailsCardProps {
  item: Item;
  location?: 'details' | 'other';
}

export const ItemDetailsCard: FC<ItemDetailsCardProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <ItemDetailsCardDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <ItemDetailsCardAuthentique {...props} />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <ItemDetailsCardVera {...props} />;
  }
};

ItemDetailsCard.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
};
