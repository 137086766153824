import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Divider, Grid, Skeleton, Typography } from '@material-ui/core';
import {
  HorizontalSplitRounded as JobsIcon,
  LayersRounded as SKUsIcon,
  Extension as ExternalIdIcon,
} from '@material-ui/icons';
import { useAuth } from '../hooks/use-auth';
import { useAxios } from '../hooks/use-axios';
import { Job as IJob, Task } from '../types/job';
import { ListResponse, ResponseData } from '../types/axios';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../icons/exclamation-outlined';
import { CustomCube as ItemIcon } from '../icons/custom-cube';
import { getJobTitle } from '../utils/jobs-helper';
import { getHelmetTitle } from '../utils/utils';
import { ItemDetailsCard } from '../components/item/item-card-details';
import { ItemAssetsCard } from '../components/item/item-card-assets';
import { SKUDetailsCard } from '../components/sku/sku-card-details';
import { SKUAssetsCard } from '../components/sku/sku-card-assets';
import { JobDetailsCard } from '../components/job/job-card-details';
import { JobTasksCard } from '../components/job/job-card-tasks';
import { JobStatusCard } from '../components/job/job-card-status';

export const Job = () => {
  const [searchParams] = useSearchParams();
  const [source, setSource] = useState<string>(searchParams.get('source'));
  const { eventId } = useParams();
  const { axios } = useAxios();
  const { tenant } = useAuth();

  const {
    data: job,
    isLoading,
    error,
  } = useQuery<IJob>(['job', eventId, tenant], async () => {
    if (!tenant) {
      throw new Error('Tenant is missing');
    }

    // Load job
    const url = `/jobs/${eventId}`;

    const { data: response } = await axios.get<ResponseData<IJob>>(url);

    // Load tasks
    if (response?.data) {
      const tasksUrl = `/jobs/${eventId}/tasks`;
      const { data: tasksReponse } = await axios.get<ResponseData<ListResponse<Task>>>(tasksUrl);

      if (tasksReponse?.data) {
        response.data.tasks = tasksReponse.data.data;
      }
    }

    return response?.data;
  });

  const backUrl = useMemo(() => {
    switch (source) {
      case 'items':
        return `/tenants/${tenant?.id}/items`;
      case 'skus':
        return `/tenants/${tenant?.id}/skus`;
      case 'jobs':
      default:
        return `/tenants/${tenant?.id}/events`;
    }
  }, [source, tenant]);

  const backText = useMemo(() => {
    switch (source) {
      case 'items':
        return 'Items';
      case 'skus':
        return 'SKUs';
      case 'jobs':
      default:
        return 'Events';
    }
  }, [source]);

  const jobBatch = useCallback(() => {
    if (!job) {
      return <></>;
    }

    return (
      <Typography
        color="textSecondary"
        variant="overline"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          textTransform: 'none',
          gridGap: '0.25em',
        }}
      >
        <JobsIcon
          sx={{
            color: 'text.secondary',
            fontSize: '20px',
          }}
        />
        Event ID: {job.id}
      </Typography>
    );
  }, [job]);

  const itemBatch = useCallback(() => {
    if (!job.item) {
      return <></>;
    }

    return (
      <>
        <Typography
          color="textSecondary"
          variant="overline"
          sx={{
            ml: 2,
            display: 'inline-flex',
            alignItems: 'center',
            textTransform: 'none',
            gridGap: '0.25em',
          }}
        >
          <ItemIcon
            sx={{
              color: 'text.secondary',
              fontSize: '20px',
            }}
          />
          Item UID: {job.item?.uniqueId}
        </Typography>
        {Boolean(job.item?.externalId) && (
          <Typography
            color="textSecondary"
            variant="overline"
            sx={{
              ml: 2,
              display: 'inline-flex',
              alignItems: 'center',
              textTransform: 'none',
              gridGap: '0.25em',
            }}
          >
            <ExternalIdIcon
              sx={{
                color: 'text.secondary',
                fontSize: '20px',
              }}
            />
            Item Ext. ID: {job.item?.externalId}
          </Typography>
        )}
      </>
    );
  }, [job]);

  const skuBatch = useCallback(() => {
    const sku = job.sku || job.item?.sku;
    if (!sku) {
      return <></>;
    }

    return (
      <Typography
        color="textSecondary"
        variant="overline"
        sx={{
          ml: 2,
          display: 'inline-flex',
          alignItems: 'center',
          textTransform: 'none',
          gridGap: '0.25em',
        }}
      >
        <SKUsIcon
          sx={{
            color: 'text.secondary',
            fontSize: '20px',
          }}
        />
        SKU Title: {sku.title}
      </Typography>
    );
  }, [job]);

  const renderContent = useCallback(() => {
    if (isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (error || !job) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3,
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              {(error as any)?.response?.data?.error?.message ||
                (error as any)?.message ||
                `Unable to load event with id:'${eventId}'.`}
            </Typography>
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              If you get here through an events list, event was probably canceled or you don&apos;t
              have permmission to see it.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to={backUrl}
                variant="text"
              >
                Go back
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }

    const sku = job?.sku || job?.item?.sku;
    const { item } = job;

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to={backUrl}
              variant="text"
            >
              {backText}
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography color="textPrimary" variant="h4" noWrap>
              {getJobTitle(job.title)}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
          <Box
            sx={{
              display: 'center',
              alignItems: 'center',
              mb: 4,
            }}
          >
            {jobBatch()}
            {itemBatch()}
            {skuBatch()}
          </Box>
          <Divider />
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.default',
            flexGrow: 1,
          }}
        >
          <Grid container spacing={3}>
            <Grid
              container
              item
              lg={8}
              spacing={3}
              sx={{
                height: 'fit-content',
                order: {
                  md: 2,
                  xs: 1,
                },
              }}
              xs={12}
            >
              <Grid item xs={12}>
                <JobDetailsCard job={job} />
              </Grid>
              <Grid item xs={12}>
                <JobTasksCard job={job} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              lg={4}
              spacing={3}
              sx={{
                height: 'fit-content',
                order: {
                  md: 2,
                  xs: 1,
                },
              }}
              xs={12}
            >
              <Grid item xs={12}>
                <JobStatusCard job={job} />
              </Grid>
              {item && (
                <>
                  <Grid item xs={12}>
                    <ItemDetailsCard item={item} location="other" />
                  </Grid>
                  <Grid item xs={12}>
                    <ItemAssetsCard item={item} location="event" />
                  </Grid>
                </>
              )}
              {sku && (
                <>
                  <Grid item xs={12}>
                    <SKUDetailsCard sku={sku} location="other" />
                  </Grid>
                  {sku?.assets?.length > 0 && (
                    <Grid item xs={12}>
                      <SKUAssetsCard sku={sku} location="event" />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [backText, backUrl, error, eventId, itemBatch, job, skuBatch]);

  useEffect(() => {
    if (searchParams.get('source')) {
      setSource(searchParams.get('source'));
    }

    return () => {
      setSource('');
    };
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Event Detail | {getHelmetTitle()} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
