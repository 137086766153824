import { FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Card, CardContent, CardHeader, Divider, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import ReactJson from 'react-json-view';
import { Job } from '../../types/job';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { getJobTitle, isEventRunnable } from '../../utils/jobs-helper';

interface JobDetailsCardProps {
  job: Job;
}

export const JobDetailsCard: FC<JobDetailsCardProps> = (props) => {
  const { job, ...other } = props;

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const align = mdDown ? 'vertical' : 'horizontal';

  const showMetadata = useMemo(() => Object.keys(job.publicMetadata).length > 0, [job]);

  const runnableJob = isEventRunnable(job);

  return (
    <Card variant="outlined" {...other}>
      <CardHeader title="Event Details" />
      <Divider />
      <PropertyList>
        <PropertyListItem align={align} label="Event ID" value={job.id} />
        <PropertyListItem align={align} label="Title" value={getJobTitle(job.title)} />
        <PropertyListItem align={align} label="Created by" value={job.user?.email} />
        {runnableJob && (
          <>
            <PropertyListItem
              align={align}
              label="Started at"
              value={format(new Date(job.startedAt), 'h:mmaaa MM/dd/yyyy')}
            />
            <PropertyListItem
              align={align}
              label="Finished at"
              value={format(new Date(job.finishedAt), 'h:mmaaa MM/dd/yyyy')}
            />
            <PropertyListItem
              align={align}
              label="Time"
              value={Number.isFinite(job.time) ? `${(job.time / 1000).toFixed(2)} s` : '-'}
            />
          </>
        )}

        {showMetadata && <PropertyListItem align={align} label="Metadata" />}
      </PropertyList>
      {showMetadata && (
        <CardContent
          sx={{
            pt: 0,
          }}
        >
          <ReactJson src={job.publicMetadata} name={false} />
        </CardContent>
      )}
    </Card>
  );
};

JobDetailsCard.propTypes = {
  // @ts-ignore
  job: PropTypes.object.isRequired,
};
