import { useCallback, useEffect, useState } from 'react';
import Uppy, { UploadResult, UppyFile, UppyOptions, State as UppyState } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { FileError } from 'react-dropzone';
import {
  UploaderSimple as Uploader,
  UploaderSimpleProps as UploaderProps,
} from '../components/uploader-simple';
import { useAuth } from './use-auth';
import { AssetType, UppyFileMetadata, UppyS3Helper } from '../utils/uppy-S3-helper';
import { useAxios } from './use-axios';
import { RemoteFileType } from '../types/remote-file';

const THUMBNAIL_WIDTH = 200;
const OVERVIEW_WIDTH = 800;

interface UploaderObject {
  id?: string;
}

const getUploadParameters = async (file: UppyFile<UppyFileMetadata>) => {
  if (!file?.meta?.preSignedS3UploadUrl) {
    throw new Error('No presigned S3 URL provided');
  }

  return {
    method: 'PUT',
    url: file.meta.preSignedS3UploadUrl,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': file.type,
    },
  };
};

/**
 * Async Square Image Validator
 * @param file Droped file
 * @returns File Error or null
 */
export const squareImageValidator = async (file: File) =>
  new Promise<FileError | FileError[]>((resolve) => {
    const i = new Image();
    i.src = URL.createObjectURL(file);
    i.onload = () => {
      const SQUARE_THRESHOLD = 0.05;
      return Math.abs(i.width / i.height - 1) < SQUARE_THRESHOLD
        ? resolve(null)
        : resolve({
            code: 'image-not-square',
            message: 'Image width and height must be same',
          });
    };
  });

export type UploadPromiseResult = void | UploadResult<
  Record<string, unknown>,
  Record<string, unknown>
>;

export interface UseUploaderResult {
  uppy: Uppy;
  uploaderProps: UploaderProps;
  Uploader?: any;
  upload: () => Promise<UploadPromiseResult>;
  isLoading: boolean;
  error?: string;
}

export interface UseUploaderProps {
  uploaderObject?: UploaderObject;
  options?: UppyOptions;
  uppyState?: UppyState;
  customUploaderProps?: Partial<UploaderProps>;
  generateThumbnail?: boolean;
  generateOverview?: boolean;
  returnImageDimensions?: boolean;
  fileType?: RemoteFileType;
}

const FILE_URL = '/files';

export const useUploader = ({
  uploaderObject,
  options,
  uppyState,
  customUploaderProps,
  generateThumbnail = true,
  generateOverview = true,
  returnImageDimensions = false,
  fileType = (process.env.REACT_APP_DEFAULT_FILE_TYPE as RemoteFileType) || RemoteFileType.S3,
}: UseUploaderProps): UseUploaderResult => {
  const { axios } = useAxios();
  const { tenant } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [uppy] = useState(
    new Uppy({
      allowMultipleUploadBatches: false,
      autoProceed: false,
      ...options,

      restrictions: {
        allowedFileTypes: ['image/*'],
        ...options?.restrictions,

        // maxNumberOfFiles: 1,
      },
    })
      .use(UppyS3Helper, {
        url: FILE_URL,
        tenantId: tenant?.id,
        fileType,
        axios,
        returnImageDimensions,
      })
      .use(AwsS3, {
        getUploadParameters,
      })
      .use(ThumbnailGenerator, {
        id: 'ThumbnailGenerator',
        thumbnailWidth: THUMBNAIL_WIDTH,
        waitForThumbnailsBeforeUpload: true,
      })
      .use(ThumbnailGenerator, {
        id: 'OverviewGenerator',
        thumbnailWidth: OVERVIEW_WIDTH,
        waitForThumbnailsBeforeUpload: true,
      }),
  );

  const [uploaderProps, setUploaderProps] = useState<UploaderProps>({
    ...customUploaderProps,
    uppy,
    uppyState: uppy.getState(),
  });

  const upload = useCallback(async () => {
    if (uppy.getFiles().length) {
      setIsLoading(true);

      const onFinally = () => {
        setIsLoading(false);
      };
      const onError = (err: string) => {
        setError(err);
      };

      return uppy.upload().catch(onError).finally(onFinally);
    }

    setError('No files to upload');

    return Promise.reject(new Error('No files to upload'));
  }, [uppy]);

  useEffect(() => {
    uppy.on('file-added', () => {
      setError(undefined);
    });
    uppy.on('file-removed', () => {
      if (uppy.getFiles().length !== 0) {
        uppy.cancelAll();
      }
    });
    uppy.on('upload-success', (file) => {
      const uploadTime = Date.now() - file.progress?.uploadStarted;
      uppy.setFileMeta<UppyFileMetadata>(file.id, {
        ...file.meta,
        uploadTime,
      });
    });
  }, [uppy]);

  useEffect(() => {
    if (generateThumbnail || generateOverview) {
      uppy.on('thumbnail:generated', (file: UppyFile<UppyFileMetadata>, preview) => {
        const img = new Image();
        img.src = preview;
        img.onload = async () => {
          const { width } = img;

          if (width === THUMBNAIL_WIDTH && !generateThumbnail) {
            return;
          }
          if (width === OVERVIEW_WIDTH && !generateOverview) {
            return;
          }

          const [f1, ...f] = file.name.split('.');
          let title = '';
          let assetType: AssetType;

          if (width === THUMBNAIL_WIDTH) {
            title = `${f1}-thumbnail.${f.join('.')}`;
            assetType = AssetType.THUMBNAIL;
          }

          if (width === OVERVIEW_WIDTH) {
            title = `${f1}-overview.${f.join('.')}`;
            assetType = AssetType.OVERVIEW;
          }

          const blob = await fetch(preview).then((r) => r.blob());

          uppy.addFile({
            data: blob,
            type: file.type,
            extension: file.extension,
            preview,
            name: title,
            meta: {
              ...file.meta,
              assetType,
            },
            source: 'Local',
          });
        };
      });
    }
  }, [uppy, generateThumbnail, generateOverview]);

  useEffect(() => {
    uppy.setState(uppyState);

    setUploaderProps({
      ...customUploaderProps,
      uppy,
      uppyState: uppy.getState(),
    });

    setError(undefined);
  }, [uppy, uppyState, customUploaderProps]);

  useEffect(() => {
    uppy.getPlugin<UppyS3Helper>('UppyS3Helper').setOptions({
      url: FILE_URL,
      tenantId: tenant?.id,
      itemId: uploaderObject?.id,
      fileType,
      returnImageDimensions,
    });
  }, [uppy, tenant, uploaderObject, fileType, returnImageDimensions]);

  return {
    uppy,
    uploaderProps,
    Uploader,
    upload,
    isLoading,
    error,
  };
};
