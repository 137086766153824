import { FC, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, Divider, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import ReactJson from 'react-json-view';
import type { SKU } from '../../../../types/sku';
import { PropertyList } from '../../../../components/property-list';
import { PropertyListItem } from '../property-list-item';
import { useDialog } from '../../../../hooks/use-dialog';
import { SKUEditDetailsDialog } from '../../../../components/sku/sku-edit-details-dialog';
import { ConfirmationDialog } from '../../../../components/confirmation-dialog';
import { useAuth } from '../../../../hooks/use-auth';

interface SKUDetailsCardProps {
  sku: SKU;
  location?: 'details' | 'other';
}

const valueOrNull = (value?: string) => value || 'N/A';

export const SKUDetailsCard: FC<SKUDetailsCardProps> = (props) => {
  const { sku, location, ...other } = props;
  const { tenant } = useAuth();

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const align = mdDown ? 'vertical' : 'horizontal';

  const [open, handleOpen, handleClose] = useDialog();
  const [openWarning, handleOpenWarning, handleCloseWarning] = useDialog();

  const showMetadata = useMemo(() => Object.keys(sku.publicMetadata).length > 0, [sku]);

  const handleWarningConfirm = useCallback(() => {
    handleCloseWarning();
    handleOpen();
  }, []);

  return (
    <>
      <Card variant="outlined" {...other}>
        <CardHeader
          action={
            location === 'details' ? tenant.isActive && (
              <Button
                color="primary"
                onClick={sku.isLocked ? handleOpenWarning : handleOpen}
                variant="text"
              >
                Edit
              </Button>
            ) : (
              <Button
                color="primary"
                component={RouterLink}
                to={`/tenants/${tenant?.id}/skus/${sku.id}`}
                variant="text"
              >
                Show
              </Button>
            )
          }
          title="SKU Properties"
        />
        <Divider />
        <PropertyList>
          <PropertyListItem align={align} label="Title" value={valueOrNull(sku.title)} />
          {showMetadata && <PropertyListItem align={align} label="Metadata" />}
        </PropertyList>
        {showMetadata && (
          <CardContent
            sx={{
              pt: 0,
            }}
          >
            <ReactJson src={sku.publicMetadata} name={false} />
          </CardContent>
        )}
      </Card>
      {openWarning && (
        <ConfirmationDialog
          open={openWarning}
          onCancel={handleCloseWarning}
          onConfirm={handleWarningConfirm}
          variant="info"
          message="SKU is active. Are you sure you want to edit this SKU?"
          title="Edit Active SKU"
        />
      )}
      {open && <SKUEditDetailsDialog open={open} onClose={handleClose} sku={sku} />}
    </>
  );
};

SKUDetailsCard.defaultProps = {
  location: 'details',
};

SKUDetailsCard.propTypes = {
  // @ts-ignore
  sku: PropTypes.object.isRequired,
  location: PropTypes.oneOf(['details', 'other']),
};
