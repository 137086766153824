import { FC } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { EventStatus } from './event-status';
import { Job } from '../../types/job';

interface JobStatusCardProps {
  job: Job;
}

export const JobStatusCard: FC<JobStatusCardProps> = (props) => {
  const { job, ...other } = props;

  return (
    <>
      <Card variant="outlined" {...other}>
        <CardHeader title="Event Status" variant="outlined" />
        <Divider />
        <CardContent>
          <EventStatus event={job} />
          <Typography
            sx={{
              color: 'text.secondary',
              display: 'block',
            }}
            variant="caption"
          >
            {`Created ${format(new Date(job.createdAt), 'MM/dd/yyyy h:mmaaa')}`}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

JobStatusCard.propTypes = {
  // @ts-ignore
  job: PropTypes.object,
};
