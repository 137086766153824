import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { InputField } from '../../../../components/input-field';
import { SKU } from '../../../../types/sku';
import { useAuth } from '../../../../hooks/use-auth';
import { useAxios } from '../../../../hooks/use-axios';
import { ResponseData } from '../../../../types/axios';

interface SKUDuplicateDialogProps {
  open: boolean;
  onClose: () => void;
  sku: SKU;
}

export const SKUEditDetailsDialog: FC<SKUDuplicateDialogProps> = ({
  open,
  onClose,
  sku,
  ...other
}) => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();
  const { tenant } = useAuth();

  const updateMutation = useMutation(async (updates: Partial<SKU>) => {
    if (!tenant) {
      throw new Error('Tenant is missing');
    }

    const url = `/skus/${sku.id}`;

    const data = {
      tenantId: tenant?.id,
      ...updates,
    };

    return axios.put<ResponseData<SKU>>(url, data);
  });

  const initialValues = {
    title: sku.title || '',
    publicMetadata: {
      widthCm: sku.publicMetadata?.widthCm || '',
      heightCm: sku.publicMetadata?.heightCm || '',
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      submit: null,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      publicMetadata: Yup.object().shape({
        widthCm: Yup.number().min(0).optional(),
        heightCm: Yup.number().min(0).optional(),
      }),
    }),
    onSubmit: async (values, helpers) => {
      const { submit, ...vals } = values;

      const newSKU: Partial<SKU> = {
        ...vals,
      };

      // Make sure all data are in string
      if (newSKU.publicMetadata) {
        Object.entries(newSKU.publicMetadata).forEach(([key, value]) => {
          newSKU.publicMetadata[key] = value?.toString() || '';
        });
      }

      updateMutation.mutate(newSKU, {
        onSuccess: () => {
          toast.success(`SKU's properties were updated`);
          queryClient.invalidateQueries('sku');
          onClose();
          helpers.setStatus({ success: true });
        },
        onError: (err: any) => {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err?.response?.data?.error?.message || err?.message });
        },
        onSettled: () => {
          helpers.setSubmitting(false);
        },
      });
    },
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
        },
      }}
      TransitionProps={{
        onExited: () => formik.resetForm(),
      }}
      {...other}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit SKU Properties</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridAutoFlow: 'row',
            }}
          >
            {/* Title */}
            <Box sx={{ mb: 3 }}>
              <InputField
                error={Boolean(formik.touched.title && formik.errors.title)}
                fullWidth
                helperText={formik.touched.title && formik.errors.title}
                label="Title"
                name="title"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
                required
              />
              <Typography color="textSecondary">
                It can be edited afterwards until the item of this SKU is protected.
              </Typography>
            </Box>

            {/* Size */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Size</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* Width CM */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.widthCm && formik.errors.publicMetadata?.widthCm,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.widthCm && formik.errors.publicMetadata?.widthCm
                  }
                  label="Width (cm)"
                  name="publicMetadata.widthCm"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.widthCm}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                {/* Height CM */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.heightCm &&
                      formik.errors.publicMetadata?.heightCm,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.heightCm &&
                    formik.errors.publicMetadata?.heightCm
                  }
                  label="Height (cm)"
                  name="publicMetadata.heightCm"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.heightCm}
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
          {formik.errors.submit && (
            <FormHelperText error sx={{ mb: 2 }}>
              {formik.errors.submit}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            color="primary"
            type="submit"
            size="large"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SKUEditDetailsDialog.defaultProps = {
  open: false,
};

SKUEditDetailsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sku: PropTypes.any,
};
