import { FC } from 'react';
import { SKU } from '../../types/sku';
import { ETemplateType } from '../../templates/types';
import { SKUEditAssetsDialog as SKUEditAssetsDialogDefault } from '../../templates/default/components/sku/sku-edit-assets-dialog';
import { SKUEditAssetsDialog as SKUEditAssetsDialogAuthentique } from '../../templates/authentique/components/sku/sku-edit-assets-dialog';

interface SKUEditAssetsDialogProps {
  open: boolean;
  onClose: () => void;
  sku: SKU;
}

export const SKUEditAssetsDialog: FC<SKUEditAssetsDialogProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <SKUEditAssetsDialogDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <SKUEditAssetsDialogAuthentique {...props} />;
  }
};
