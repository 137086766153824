import { EFingerprintTitle, FingerprintLocation } from '../types/fingerprint';
import { Item } from '../types/item';

interface CalculateFingerprintLocationArgs {
  imageWidthPx: number;
  imageHeightPx: number;
  realWidthCm: number;
  realHeightCm: number;
}

export const calculateFingerprintLocation = ({
  imageWidthPx,
  imageHeightPx,
  realHeightCm,
  realWidthCm,
}: CalculateFingerprintLocationArgs): FingerprintLocation => {
  const phoneAreaWidthCm = 7.5;
  const phoneAreaHeightCm = 10.0;

  const width = Math.round(imageWidthPx * (phoneAreaWidthCm / realWidthCm));
  const height = Math.round(imageHeightPx * (phoneAreaHeightCm / realHeightCm));

  let x = 0;
  let y = 0;

  if (width !== imageWidthPx) {
    x = Math.round((imageWidthPx - width) / 2.0);
  }

  if (height !== imageHeightPx) {
    y = Math.round((imageHeightPx - height) / 2.0);
  }

  return {
    x,
    y,
    width,
    height,
  };
};

export const getDefaultFingerprint = (item: Item) => {
  // Default fingerprint title
  if (!item || item.fingerprints.length <= 0) {
    return undefined;
  }

  // Default fingerprint from VERA AI
  const frontProtection = item.fingerprints.find(
    (fp) => fp.title === EFingerprintTitle.PROTECTION_FRONT,
  );
  if (frontProtection) {
    return frontProtection;
  }

  return item.fingerprints[0];
};

export const getFingerprintTitle = (fingerprintTitle?: EFingerprintTitle) => {
  switch (fingerprintTitle) {
    case EFingerprintTitle.PROTECTION:
      return 'Protection';
    case EFingerprintTitle.PROTECTION_FRONT:
      return 'Protection Front';
    case EFingerprintTitle.PROTECTION_BACK:
      return 'Protection Back';
    case EFingerprintTitle.REGISTRATION:
      return 'Registration';
    default:
      return '';
  }
};
