import { FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, Divider, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import ReactJson from 'react-json-view';
import type { Item } from '../../../../types/item';
import { PropertyList } from '../../../../components/property-list';
import { PropertyListItem } from '../../../../components/property-list-item';
import { useAuth } from '../../../../hooks/use-auth';

interface ItemDetailsCardProps {
  item: Item;
  location?: 'details' | 'other';
}

export const ItemDetailsCard: FC<ItemDetailsCardProps> = (props) => {
  const { item, location, ...other } = props;
  const { tenant } = useAuth();

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const align = mdDown ? 'vertical' : 'horizontal';

  const showMetadata = useMemo(() => Object.keys(item.publicMetadata).length > 0, [item]);

  return (
    <Card variant="outlined" {...other}>
      <CardHeader
        title="Item Details"
        action={
          location === 'other' && (
            <Button
              color="primary"
              component={RouterLink}
              to={`/tenants/${tenant?.id}/items/${item.id}`}
              variant="text"
            >
              Show
            </Button>
          )
        }
      />
      <Divider />
      <PropertyList>
        <PropertyListItem align={align} label="Unique ID" value={item.uniqueId} />
        <PropertyListItem align={align} label="Title" value={item.title} />
        {item.subtitle && <PropertyListItem align={align} label="Subtitle" value={item.subtitle} />}
        {item.externalId && (
          <PropertyListItem align={align} label="External ID" value={item.externalId} />
        )}

        {item.protectedBy && (
          <PropertyListItem align={align} label="Protected by" value={item.protectedBy?.email} />
        )}
        {item.protectedAt && (
          <PropertyListItem
            align={align}
            label="Protected at"
            value={format(new Date(item.protectedAt), 'h:mmaaa MM/dd/yyyy')}
          />
        )}
        {item.registeredBy && (
          <PropertyListItem align={align} label="Registered by" value={item.registeredBy?.email} />
        )}
        {item.registeredAt && (
          <PropertyListItem
            align={align}
            label="Registered at"
            value={format(new Date(item.registeredAt), 'h:mmaaa MM/dd/yyyy')}
          />
        )}
        {showMetadata && <PropertyListItem align={align} label="Metadata" />}
      </PropertyList>
      {showMetadata && (
        <CardContent
          sx={{
            pt: 0,
          }}
        >
          <ReactJson src={item.publicMetadata} name={false} />
        </CardContent>
      )}
    </Card>
  );
};

ItemDetailsCard.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
  location: PropTypes.oneOf(['details', 'other']),
};
