import { SKU } from '../../../types/sku';
import { convertToInch } from '../../../utils/convert-unit';

export const SKU_MAX_WIDTH = 227;
export const SKU_MAX_HEIGHT = 150;
export const SKU_MAX_DEPTH = 10;

export const skuSizeFormat = (sku: SKU, inInches?: boolean) => {
  const { width, height, depth } = sku.publicMetadata ?? {};
  if (!width || !height) {
    return '-';
  }

  let size = [width, height].map((m) => m ?? 0);
  if (depth) {
    size.push(depth);
  }

  if (inInches) {
    size = size.map((m) => convertToInch(Number(m)).toFixed(2));
  }

  return size.join('×');
};

export const skuOffsetFormat = (sku: SKU, offsetBack?: boolean) => {
  const p = sku.publicMetadata ?? {};
  let top = p.offsetFrontTop;
  let right = p.offsetFrontRight;
  let bottom = p.offsetFrontBottom;
  let left = p.offsetFrontLeft;

  if (offsetBack) {
    top = p.offsetBackTop;
    right = p.offsetBackRight;
    bottom = p.offsetBackBottom;
    left = p.offsetBackLeft;
  }

  if (!top || !right || !bottom || !left) {
    return '-';
  }

  return [top, right, bottom, left].map((m) => m ?? 0).join('×');
};
