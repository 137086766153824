import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DropzoneOptions, FileError } from 'react-dropzone';
import Uppy, { UppyFile, State as UppyState } from '@uppy/core';
import { StatusBar } from '@uppy/react';
import toast from 'react-hot-toast';
import { Box, Card, CardContent, IconButton, Typography } from '@material-ui/core';
import { Trash as TrashIcon } from '../icons/trash';
import { ImageDropzone } from './image-dropzone';
import { UppyFileMetadata } from '../utils/uppy-S3-helper';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';

export type UploaderFileType = 'image' | 'other';

interface UploaderStrings {
  title: string;
  upload: string;
  helperText: string;
}

const DEFAULT_STRINGS: UploaderStrings = {
  title: 'Image',
  upload: 'Upload',
  helperText: 'Select Image',
};

interface ShownFile {
  src: string;
  name?: string;
  type: UploaderFileType;
}

interface UploadFile extends ShownFile {
  fileId?: string;
}

type AsyncValidator = <T extends File>(file: T) => Promise<FileError | FileError[] | null>;

const validateStrictFilename = (file: File) => {
  if (file.name.includes(' ')) {
    return {
      code: 'filename-spaces',
      message: 'File name cannnot contain spaces',
    };
  }
  return null;
};

const validate = (
  file: File,
  validator?: DropzoneOptions['validator'],
  strictFilename: boolean = false,
  onStrictFilenameError?: (message?: string) => void,
) => {
  if (strictFilename) {
    const res = validateStrictFilename(file);
    if (res) {
      if (onStrictFilenameError) {
        onStrictFilenameError(res.message);
      }
      return res;
    }
  }
  return validator ? validator(file) : null;
};

export interface UploaderSimpleProps {
  uppy: Uppy;
  uppyState: UppyState;
  title?: string;
  shownFile?: ShownFile;
  accept?: DropzoneOptions['accept'];
  validator?: DropzoneOptions['validator'];
  asyncValidator?: AsyncValidator;
  variant?: 'outlined' | 'clean';
  compact?: boolean;
  flexGrow?: boolean;
  customStrings?: Partial<UploaderStrings>;
  showTitle?: boolean;
  fullWidth?: boolean;
  strictFilename?: boolean;
  onStrictFilenameError?: (message?: string) => void;
  onFileRemoved?: (file?: UppyFile<UppyFileMetadata>) => void;
}

export const UploaderSimple: FC<UploaderSimpleProps> = ({
  uppy,
  uppyState,
  title,
  shownFile,
  accept,
  validator,
  asyncValidator,
  variant = 'outlined',
  compact,
  flexGrow = true,
  showTitle = true,
  customStrings,
  fullWidth,
  strictFilename,
  onStrictFilenameError,
  onFileRemoved,
}) => {
  const [selectedFile, setSelectedFile] = useState<UploadFile>(shownFile);

  const strings = useMemo(() => ({ ...DEFAULT_STRINGS, ...customStrings }), [customStrings]);

  const handleDeleteImage = (fileId: string): void => {
    setSelectedFile(undefined);
    if (fileId) {
      uppy.removeFile(fileId);
    } else if (onFileRemoved) {
      onFileRemoved();
    }
  };

  const dropHandle = async (acceptetFiles: any[]) => {
    const [file] = acceptetFiles;

    if (file) {
      const src = URL.createObjectURL(file);
      if (asyncValidator) {
        const error = await asyncValidator(file);

        // File is not valid
        if (error) {
          const [{ message }] = [error].flat();
          toast.error(message || 'File validation failed');
          return;
        }
      }
      const blob = await fetch(src).then((r) => r.blob());

      const fileId = uppy.addFile({
        name: file.name,
        type: file.type,
        data: blob,
        source: 'Local',
        isRemote: false,
      });

      setSelectedFile({
        fileId,
        name: file.name,
        src,
        type: file.type?.includes('image') ? 'image' : 'other',
      });
    }
  };

  useEffect(() => {
    uppy.setState(uppyState);

    const files = uppy.getFiles();
    if (files.length) {
      const [file] = files;

      const src = URL.createObjectURL(file?.data);

      setSelectedFile({
        fileId: file.id,
        name: file.name,
        src,
        type: file.type?.includes('image') ? 'image' : 'other',
      });
    }
  }, [uppy, uppyState]);

  useEffect(() => {
    uppy.on('file-removed', (file) => {
      if (onFileRemoved) {
        onFileRemoved(file);
      }
    });
  }, [uppy]);

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: 150,
        ...(fullWidth && {
          width: '100%',
        }),
        ...(variant === 'clean' && {
          border: 'none',
        }),
        ...(flexGrow && {
          flex: 1,
        }),
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          ...(variant === 'clean' && {
            p: 0,
          }),
        }}
      >
        {showTitle && (
          <Typography
            color="textPrimary"
            sx={{
              mb: 1.25,
              ...(compact && {
                mb: 0.5,
                lineHeight: '1.4375em',
              }),
            }}
            variant="subtitle2"
          >
            {title || strings.title}
          </Typography>
        )}
        <Box
          sx={{
            height: '100%',
            ...(compact && {
              height: 130,
            }),
            overflow: 'hidden',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: '1fr',
            position: 'relative',
            '& img': {
              borderRadius: 1,
              maxWidth: '100%',
              maxHeight: '100%',
            },
          }}
        >
          {/* Dropzone */}
          <ImageDropzone
            onDrop={dropHandle}
            validator={(file) => validate(file, validator, strictFilename, onStrictFilenameError)}
            accept={accept || ['image/*']}
            maxFiles={1}
            sx={{
              display: selectedFile ? 'none' : 'flex',
            }}
            uploadText={strings.upload}
            helperText={strings.helperText}
          />
          {/* Preview of selected file */}
          {selectedFile && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: '400px',
                padding: '1px',
                overflow: 'hidden',
                ...(flexGrow && {
                  maxHeight: '100%',
                }),
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  borderRadius: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  boxShadow: '1px 1px 1px 1px rgb(170, 170, 170, 0.4)',
                  '&::before': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: 1,
                    bottom: 0,
                    content: '""',
                    display: 'none',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                  '&:hover': {
                    boxShadow: (theme) => `0px 0px 0px 1px ${theme.palette.primary.main}`,
                    '&::before': {
                      display: 'block',
                    },
                    '& button': {
                      display: 'inline-flex',
                    },
                  },
                }}
              >
                {selectedFile.type === 'image' ? (
                  <img alt="to upload" src={selectedFile.src} />
                ) : (
                  <Box
                    sx={{
                      textAlign: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                      p: 2,
                    }}
                  >
                    {selectedFile.name}
                  </Box>
                )}
                <IconButton
                  color="primary"
                  onClick={() => handleDeleteImage(selectedFile.fileId)}
                  sx={{
                    bottom: 8,
                    color: 'text.secondary',
                    display: 'none',
                    position: 'absolute',
                    right: 8,
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
            </Box>
          )}
          {/* Status bar */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              opacity: 0.9,
              borderRadius: '0 0 6px 6px',
              overflow: 'hidden',
            }}
          >
            <StatusBar uppy={uppy} hideUploadButton hideAfterFinish={false} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

UploaderSimple.propTypes = {
  uppy: PropTypes.instanceOf(Uppy),
  uppyState: PropTypes.any,
  title: PropTypes.string,
  shownFile: PropTypes.any,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  validator: PropTypes.func,
  asyncValidator: PropTypes.func,
  variant: PropTypes.oneOf(['outlined', 'clean']),
  compact: PropTypes.bool,
  flexGrow: PropTypes.bool,
  showTitle: PropTypes.bool,
  customStrings: PropTypes.any,
  fullWidth: PropTypes.bool,
  strictFilename: PropTypes.bool,
  onStrictFilenameError: PropTypes.any,
  onFileRemoved: PropTypes.func,
};
