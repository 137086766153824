import Uppy, { BasePlugin, PluginOptions, UploadedUppyFile } from '@uppy/core';
import { AxiosInstance } from 'axios';
import {
  RemoteFile,
  CreateRemoteFileRequestData,
  RemoteFileType,
  RemoteFileTTL,
} from '../types/remote-file';
import logger from './logger';
import { ResponseData } from '../types/axios';

export enum AssetType {
  ORIGINAL = 'original',
  THUMBNAIL = 'thumbnail',
  OVERVIEW = 'overview',
}

export interface UppyFileMetadata {
  preSignedS3UploadUrl?: string;
  preSignedS3DownloadUrl?: string;
  remoteFileId?: string;
  assetType?: AssetType;
  uploadTime?: number;
  width?: number;
  height?: number;
}

interface RemoteFileData {
  fileId: string;
  data: RemoteFile;
}
export interface UppyS3HelperOptions extends PluginOptions {
  url: string;
  tenantId?: string;
  itemId?: string;
  fileType?: RemoteFileType;
  axios: AxiosInstance;
  returnImageDimensions?: boolean;
}

export class UppyS3Helper extends BasePlugin<UppyS3HelperOptions> {
  opts: UppyS3HelperOptions;

  constructor(uppy: Uppy, opts: UppyS3HelperOptions) {
    super(uppy, opts);

    this.id = opts.id || 'UppyS3Helper';
    this.type = 'modifier';
    this.opts = { ...opts };
    this.prepareUpload = this.prepareUpload.bind(this);
  }

  async getS3Parameters(fileName: string) {
    logger('[Uppy plugin] File name', fileName);

    if (!this.opts?.url || !this.opts?.axios) {
      throw new Error(`Cannot create file ${fileName}`);
    }

    const { url, tenantId, itemId, fileType, axios } = this.opts;

    const data: CreateRemoteFileRequestData = {
      title: fileName,
      itemId,
      tenantId,
      type:
        fileType ||
        (process.env.REACT_APP_DEFAULT_FILE_TYPE as RemoteFileType) ||
        RemoteFileType.S3,
      ttl: RemoteFileTTL.PERMANENT,
    };

    return axios.post<ResponseData<RemoteFile>>(url, data);
  }

  async getImageDimensions(file: File) {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        reject(new Error('Cannot get image dimensions'));
      };
      img.src = URL.createObjectURL(file);
    });
  }

  async prepareUpload(fileIds: string[]) {
    logger('[Uppy plugin] Files', fileIds);

    const promises = fileIds.map((fileId) => {
      const file = this.uppy.getFile(fileId);
      this.uppy.emit('s3-params-start', file);

      const prepareFileToUpload = async () => {
        if (this.opts.returnImageDimensions) {
          const dims = await this.getImageDimensions(file.data as File);
          this.uppy.setFileMeta<UppyFileMetadata>(fileId, {
            width: dims.width,
            height: dims.height,
          });
        }

        return this.getS3Parameters(file.name);
      };

      return prepareFileToUpload()
        .then((res) => {
          const {
            data: { data },
          } = res;

          this.uppy.setFileMeta<UppyFileMetadata>(fileId, {
            preSignedS3UploadUrl: data.preSignedS3Url,
            preSignedS3DownloadUrl: data.url,
            remoteFileId: data.id,
            assetType: (file.meta.assetType as AssetType) || AssetType.ORIGINAL,
          });

          return { fileId, data } as RemoteFileData;
        })
        .catch((err) => {
          throw new Error(`Cannot upload file ${file.name}: ${err?.message}`);
        });
    });

    const emitPreprocessCompleteForAll = (values: RemoteFileData[]) => {
      values.forEach(({ fileId, data }) => {
        const file = this.uppy.getFile(fileId);
        this.uppy.emit('preprocess-complete', file, data);
      });
    };

    return Promise.all(promises).then(emitPreprocessCompleteForAll);
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}

export const mapUppyFileToRemoteFile = (
  file: UploadedUppyFile<UppyFileMetadata, any>,
): RemoteFile =>
  ({
    id: file.meta.remoteFileId,
    title: file.meta.name,
    url: file.meta.preSignedS3DownloadUrl,
    uploadTime: file.meta.uploadTime,
  } as RemoteFile);
