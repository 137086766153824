import { FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@material-ui/core';
import type { Job } from '../../types/job';
import { JobTask } from './job-task';

interface JobTasksCardProps {
  job: Job;
}

export const JobTasksCard: FC<JobTasksCardProps> = (props) => {
  const { job, ...other } = props;

  const singleTask = useMemo(() => job.tasks?.length === 1, [job]);

  return (
    <Card variant="outlined" {...other}>
      <CardHeader title="Tasks" />
      <Divider />
      {job.tasks?.map((task, index, arr) => (
        <div key={task.id}>
          <JobTask index={singleTask ? undefined : index} task={task} />
          {!singleTask && index !== arr.length - 1 && <Divider />}
        </div>
      ))}
    </Card>
  );
};

JobTasksCard.propTypes = {
  // @ts-ignore
  job: PropTypes.object.isRequired,
};
