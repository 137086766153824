import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { InputField } from '../../../../components/input-field';
import { SKU } from '../../../../types/sku';
import { useAuth } from '../../../../hooks/use-auth';
import { useAxios } from '../../../../hooks/use-axios';
import { ResponseData } from '../../../../types/axios';
import { convertToInch } from '../../../../utils/convert-unit';
import { SKU_MAX_HEIGHT, SKU_MAX_WIDTH } from '../../utils/sku-size-format';

interface SKUDuplicateDialogProps {
  open: boolean;
  onClose: () => void;
  sku: SKU;
}

export const SKUEditDetailsDialog: FC<SKUDuplicateDialogProps> = ({
  open,
  onClose,
  sku,
  ...other
}) => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();
  const { tenant } = useAuth();

  const updateMutation = useMutation(async (updates: Partial<SKU>) => {
    if (!tenant) {
      throw new Error('Tenant is missing');
    }

    const url = `/skus/${sku.id}`;

    const data = {
      tenantId: tenant?.id,
      ...updates,
    };

    return axios.put<ResponseData<SKU>>(url, data);
  });

  const initialValues = {
    title: sku.title || '',
    publicMetadata: {
      serialId: sku.publicMetadata.serialId || '',
      height: sku.publicMetadata.height || '',
      width: sku.publicMetadata.width || '',
      depth: sku.publicMetadata.depth || '',
      offsetFrontTop: sku.publicMetadata.offsetFrontTop || '',
      offsetFrontRight: sku.publicMetadata.offsetFrontRight || '',
      offsetFrontBottom: sku.publicMetadata.offsetFrontBottom || '',
      offsetFrontLeft: sku.publicMetadata.offsetFrontLeft || '',
      offsetBackTop: sku.publicMetadata.offsetBackTop || '',
      offsetBackRight: sku.publicMetadata.offsetBackRight || '',
      offsetBackBottom: sku.publicMetadata.offsetBackBottom || '',
      offsetBackLeft: sku.publicMetadata.offsetBackLeft || '',
      isInSleeve: sku.publicMetadata.isInSleeve?.toString() !== 'false',
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      submit: null,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      publicMetadata: Yup.object().shape({
        serialId: Yup.string().required('Serial ID is required'),
        height: Yup.number()
          .min(0, 'Height must be more than or equal to 0')
          .max(SKU_MAX_HEIGHT, `Height must be less than or equal to ${SKU_MAX_HEIGHT}`)
          .required('Height is required'),
        width: Yup.number()
          .min(0, 'Width must be more than or equal to 0')
          .max(SKU_MAX_WIDTH, `Width must be less than or equal to ${SKU_MAX_WIDTH}`)
          .required('Width is required'),
        depth: Yup.number().min(0, 'Depth must be more than or equal to 0').optional(),
        offsetFrontTop: Yup.number().optional(),
        offsetFrontRight: Yup.number().optional(),
        offsetFrontBottom: Yup.number().optional(),
        offsetFrontLeft: Yup.number().optional(),
        offsetBackTop: Yup.number().optional(),
        offsetBackRight: Yup.number().optional(),
        offsetBackBottom: Yup.number().optional(),
        offsetBackLeft: Yup.number().optional(),
        isInSleeve: Yup.boolean().optional(),
      }),
    }),
    onSubmit: async (values, helpers) => {
      const { submit, ...vals } = values;

      const newSKU: Partial<SKU> = {
        ...vals,
      };

      // Make sure all data are in string
      if (newSKU.publicMetadata) {
        Object.entries(newSKU.publicMetadata).forEach(([key, value]) => {
          newSKU.publicMetadata[key] = value?.toString() || '';
        });
      }

      updateMutation.mutate(newSKU, {
        onSuccess: () => {
          toast.success(`SKU's properties were updated`);
          queryClient.invalidateQueries('sku');
          onClose();
          helpers.setStatus({ success: true });
        },
        onError: (err: any) => {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err?.response?.data?.error?.message || err?.message });
        },
        onSettled: () => {
          helpers.setSubmitting(false);
        },
      });
    },
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
        },
      }}
      TransitionProps={{
        onExited: () => formik.resetForm(),
      }}
      {...other}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit SKU Properties</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridAutoFlow: 'row',
            }}
          >
            {/* Title */}
            <Box sx={{ mb: 3 }}>
              <InputField
                error={Boolean(formik.touched.title && formik.errors.title)}
                fullWidth
                helperText={formik.touched.title && formik.errors.title}
                label="Title"
                name="title"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
                required
              />
              <Typography color="textSecondary">
                It can be edited afterwards until the item of this SKU is protected.
              </Typography>
            </Box>
            {/* SKU Serial Number */}
            <Box sx={{ mb: 3 }}>
              <InputField
                error={Boolean(
                  formik.touched.publicMetadata?.serialId && formik.errors.publicMetadata?.serialId,
                )}
                fullWidth
                helperText={
                  formik.touched.publicMetadata?.serialId && formik.errors.publicMetadata?.serialId
                }
                label="SKU Serial Number"
                name="publicMetadata.serialId"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.publicMetadata?.serialId}
              />
            </Box>

            {/* Is In Sleeve */}
            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                sx={{
                  m: 0,
                }}
                control={
                  <Switch
                    checked={formik.values.publicMetadata?.isInSleeve}
                    name="publicMetadata.isInSleeve"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                labelPlacement="start"
                label="In Sleeve/Slab?"
              />
              {formik.errors.publicMetadata?.isInSleeve && (
                <FormHelperText error sx={{ mb: 1 }}>
                  {formik.errors.publicMetadata?.isInSleeve}
                </FormHelperText>
              )}
            </Box>

            {/* Size: Width x Height x Depth */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Size</Typography>
              </Grid>
              <Grid item xs={4}>
                {/* Width */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.width && formik.errors.publicMetadata?.width,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.width && formik.errors.publicMetadata?.width
                  }
                  label="Width (mm)"
                  name="publicMetadata.width"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.width}
                  required
                  type="number"
                />
                <InputField
                  label="Width (inches)"
                  value={convertToInch(Number(formik.values.publicMetadata?.width)).toFixed(2)}
                  type="number"
                  fullWidth
                  disabled
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: 'text.primary',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {/* Height */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.height && formik.errors.publicMetadata?.height,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.height && formik.errors.publicMetadata?.height
                  }
                  label="Height (mm)"
                  name="publicMetadata.height"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.height}
                  required
                  type="number"
                />
                <InputField
                  label="Height (inches)"
                  value={convertToInch(Number(formik.values.publicMetadata?.height)).toFixed(2)}
                  type="number"
                  fullWidth
                  disabled
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: 'text.primary',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {/* Depth */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.depth && formik.errors.publicMetadata?.depth,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.depth && formik.errors.publicMetadata?.depth
                  }
                  label="Depth (mm)"
                  name="publicMetadata.depth"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.depth}
                  type="number"
                />
                <InputField
                  label="Depth (inches)"
                  value={convertToInch(Number(formik.values.publicMetadata?.depth)).toFixed(2)}
                  type="number"
                  fullWidth
                  disabled
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: 'text.primary',
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* Front Offset: Top x Right x Bottom x Left */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Front Offset</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* Top */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetFrontTop &&
                      formik.errors.publicMetadata?.offsetFrontTop,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetFrontTop &&
                    formik.errors.publicMetadata?.offsetFrontTop
                  }
                  label="Top (px)"
                  name="publicMetadata.offsetFrontTop"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetFrontTop}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                {/* Right */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetFrontRight &&
                      formik.errors.publicMetadata?.offsetFrontRight,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetFrontRight &&
                    formik.errors.publicMetadata?.offsetFrontRight
                  }
                  label="Right (px)"
                  name="publicMetadata.offsetFrontRight"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetFrontRight}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                {/* Bottom */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetFrontBottom &&
                      formik.errors.publicMetadata?.offsetFrontBottom,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetFrontBottom &&
                    formik.errors.publicMetadata?.offsetFrontBottom
                  }
                  label="Bottom (px)"
                  name="publicMetadata.offsetFrontBottom"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetFrontBottom}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                {/* Left */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetFrontLeft &&
                      formik.errors.publicMetadata?.offsetFrontLeft,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetFrontLeft &&
                    formik.errors.publicMetadata?.offsetFrontLeft
                  }
                  label="Left (px)"
                  name="publicMetadata.offsetFrontLeft"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetFrontLeft}
                  type="number"
                />
              </Grid>
            </Grid>

            {/* Back Offset: Top x Right x Bottom x Left */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Back Offset</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* Top */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetBackTop &&
                      formik.errors.publicMetadata?.offsetBackTop,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetBackTop &&
                    formik.errors.publicMetadata?.offsetBackTop
                  }
                  label="Top (px)"
                  name="publicMetadata.offsetBackTop"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetBackTop}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                {/* Right */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetBackRight &&
                      formik.errors.publicMetadata?.offsetBackRight,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetBackRight &&
                    formik.errors.publicMetadata?.offsetBackRight
                  }
                  label="Right (px)"
                  name="publicMetadata.offsetBackRight"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetBackRight}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                {/* Bottom */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetBackBottom &&
                      formik.errors.publicMetadata?.offsetBackBottom,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetBackBottom &&
                    formik.errors.publicMetadata?.offsetBackBottom
                  }
                  label="Bottom (px)"
                  name="publicMetadata.offsetBackBottom"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetBackBottom}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                {/* Left */}
                <InputField
                  error={Boolean(
                    formik.touched.publicMetadata?.offsetBackLeft &&
                      formik.errors.publicMetadata?.offsetBackLeft,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.publicMetadata?.offsetBackLeft &&
                    formik.errors.publicMetadata?.offsetBackLeft
                  }
                  label="Left (px)"
                  name="publicMetadata.offsetBackLeft"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.publicMetadata?.offsetBackLeft}
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
          {formik.errors.submit && (
            <FormHelperText error sx={{ mb: 2 }}>
              {formik.errors.submit}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            color="primary"
            type="submit"
            size="large"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SKUEditDetailsDialog.defaultProps = {
  open: false,
};

SKUEditDetailsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sku: PropTypes.any,
};
