import { FC, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { useDialog } from '../../../../hooks/use-dialog';
import { ImageThumbnail } from '../../../../components/image-thumbnail';
import { ImageDialog } from '../../../../components/image-dialog';
import { Item } from '../../../../types/item';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';

interface ShownImage {
  title: string;
  src: string;
}

interface ItemAssetsCardProps {
  item: Item;
  location?: 'event' | 'other';
}

export const ItemAssetsCard: FC<ItemAssetsCardProps> = ({ item, location, ...other }) => {
  const [shownImage, setShownImage] = useState<ShownImage>();

  const [openImage, handleOpenImage, handleCloseImage] = useDialog();

  const handleShowImage = (image: ShownImage) => {
    setShownImage(image);
    handleOpenImage();
  };

  const displayUnavailable = useMemo(() => !item.assets || item.assets.length === 0, [item.assets]);

  const gridSizeMD = useMemo(() => (location === 'event' ? 4 : 3), [location]);
  const gridSizeSM = useMemo(() => (location === 'event' ? 6 : 4), [location]);
  const gridSizeXS = useMemo(() => (location === 'event' ? 12 : 6), [location]);

  return (
    <>
      <Card variant="outlined" {...other}>
        <CardHeader title="Item Images" />
        <Divider />
        <CardContent>
          {displayUnavailable ? (
            <ResourceUnavailable
              text="No images available at this moment."
              sx={{
                flexGrow: 1,
              }}
            />
          ) : (
            <Grid container spacing={2}>
              {item.assets?.map((asset) => (
                <Grid item md={gridSizeMD} sm={gridSizeSM} xs={gridSizeXS} key={asset.id}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {asset.title}
                  </Typography>
                  <ImageThumbnail
                    alt={asset.title}
                    src={asset.file?.url}
                    onClick={() => {
                      handleShowImage({
                        title: `Image: ${asset.title}`,
                        src: asset.file?.url,
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </CardContent>
      </Card>
      <ImageDialog
        open={openImage}
        onClose={handleCloseImage}
        title={shownImage?.title}
        src={shownImage?.src}
        maxWidth="lg"
      />
    </>
  );
};

ItemAssetsCard.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
  location: PropTypes.oneOf(['event', 'other']),
};
