import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { useDialog } from '../../hooks/use-dialog';
import { getTitle } from '../../utils/jobs-helper';
import { Task } from '../../types/job';
import { ImageThumbnail } from '../image-thumbnail';
import { ImageDialog } from '../image-dialog';
import { EFingerprintTitle } from '../../types/fingerprint';
import { getFingerprintTitle } from '../../utils/fingerprint-helper';

interface ItemTimelineListItemTasksImageProps {
  task: Task;
}

export const ItemTimelineListItemTasksImage: FC<ItemTimelineListItemTasksImageProps> = ({
  task,
}) => {
  const [imageOpen, handleImageOpen, handleImageClose] = useDialog();
  const title = getFingerprintTitle(task.publicMetadata?.fingerprintTitle as EFingerprintTitle);
  const dialogSubtitle = task.publicMetadata?.fingerprintTitle ? `: ${title}` : '';
  return (
    <Grid item xl={3} lg={6} md={4} xs={6} key={task.id}>
      {title && <Typography variant="subtitle2">{title}</Typography>}
      <ImageThumbnail src={task.file?.url} alt={task.taskName} onClick={handleImageOpen} />
      <ImageDialog
        open={imageOpen}
        onClose={handleImageClose}
        title={`${getTitle(task)}${dialogSubtitle}`}
        src={task.file?.url}
        maxWidth="lg"
      />
    </Grid>
  );
};

ItemTimelineListItemTasksImage.propTypes = {
  // @ts-ignore
  task: PropTypes.object.isRequired,
};
