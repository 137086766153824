import { FC } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Divider, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import type { Item } from '../../../../types/item';
import { PropertyList } from '../../../../components/property-list';
import { PropertyListItem } from '../../../../components/property-list-item';

interface ItemDetailsCardProps {
  item: Item;
  location?: 'details' | 'other';
}

export const ItemDetailsCard: FC<ItemDetailsCardProps> = (props) => {
  const { item, ...other } = props;

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const align = mdDown ? 'vertical' : 'horizontal';

  return (
    <Card variant="outlined" {...other}>
      <CardHeader title="Item Details" />
      <Divider />
      <PropertyList>
        <PropertyListItem align={align} label="Unique ID" value={item.uniqueId} />
        <PropertyListItem align={align} label="Title" value={item.title} />
        {item.subtitle && <PropertyListItem align={align} label="Subtitle" value={item.subtitle} />}
        {item.externalId && <PropertyListItem align={align} label="External ID" value={item.externalId} />}
        {item.protectedBy && (
          <PropertyListItem align={align} label="Protected by" value={item.protectedBy?.email} />
        )}
        {item.registeredBy && (
          <PropertyListItem align={align} label="Registered by" value={item.registeredBy?.email} />
        )}
      </PropertyList>
    </Card>
  );
};

ItemDetailsCard.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
};
