import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { LoadingButton } from '@material-ui/lab';
import { FC, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { SKU } from '../../../../types/sku';
import { useAuth } from '../../../../hooks/use-auth';
import { useAxios } from '../../../../hooks/use-axios';
import { useUploader, UseUploaderProps } from '../../../../hooks/use-uploader';
import logger from '../../../../utils/logger';
import { EAssetTitle } from '../../../../types/asset';
import { AssetType } from '../../../../utils/uppy-S3-helper';
import { ResponseData } from '../../../../types/axios';

interface SKUEditAssetsDialogProps {
  open: boolean;
  onClose: () => void;
  sku: SKU;
}

interface EditSKUAssetsFormValues {
  overviewFileId: string;
  fpAreaFileId: string;
}

export const RESET_VALUES = {
  overviewFileId: '',
  fpAreaFileId: '',
  submit: null,
};

export const SKUEditAssetsDialog: FC<SKUEditAssetsDialogProps> = ({
  open,
  onClose,
  sku,
  ...other
}) => {
  const { axios } = useAxios();
  const { tenant } = useAuth();
  const queryClient = useQueryClient();

  const overviewAsset = useMemo(
    () =>
      sku?.assets?.find(
        (asset) => asset.title === EAssetTitle.OVERVIEW || asset.title === EAssetTitle.overview,
      ),
    [sku],
  );
  const fpAreaAsset = useMemo(
    () => sku?.assets?.find((asset) => asset.title === EAssetTitle.FP_AREA),
    [sku],
  );

  const overviewUploaderOptions = useMemo(
    (): UseUploaderProps => ({
      uploaderObject: {
        id: sku?.id,
      },
      customUploaderProps: {
        accept: ['image/*'],
        title: 'Supported formats: .jpg, .png',
        shownFile: overviewAsset && {
          src: overviewAsset.file.url,
          type: 'image',
        },
        flexGrow: true,
      },
      generateOverview: false,
    }),
    [overviewAsset],
  );

  const {
    upload: overviewUpload,
    isLoading: isOverviewUploaderLoading,
    error: overviewUploaderError,
    uppy: overviewUppy,
    uploaderProps: overviewUploaderProps,
    Uploader: WalletUploader,
  } = useUploader(overviewUploaderOptions);

  const fpAreaUploaderOptions = useMemo(
    (): UseUploaderProps => ({
      uploaderObject: {
        id: sku?.id,
      },
      customUploaderProps: {
        accept: ['image/*'],
        title: 'Supported formats: .jpg, .png',
        shownFile: fpAreaAsset && {
          src: fpAreaAsset.file.url,
          type: 'image',
        },
        flexGrow: true,
      },
      generateOverview: false,
      generateThumbnail: false,
    }),
    [fpAreaAsset],
  );

  const {
    upload: fpAreaUpload,
    isLoading: isFpAreaUploaderLoading,
    error: fpAreaUploaderError,
    uppy: fpAreaUppy,
    uploaderProps: fpAreaUploaderProps,
    Uploader: FpAreaUploader,
  } = useUploader(fpAreaUploaderOptions);

  const updateMutation = useMutation(async (values: EditSKUAssetsFormValues) => {
    if (!tenant) {
      throw new Error('Tenant is missing');
    }

    const data = {
      tenantId: tenant.id,
      assets: [],
    };

    const [overviewUploadResult, fpAreaUploadResult] = await Promise.all([
      values.overviewFileId !== overviewAsset?.file.id ? overviewUpload() : Promise.resolve(),
      values.fpAreaFileId !== fpAreaAsset?.file.id ? fpAreaUpload() : Promise.resolve(),
    ]);

    if (overviewUploadResult) {
      const overviewFile = overviewUploadResult.successful.find(
        (f) => f.meta.assetType === AssetType.ORIGINAL,
      )?.meta;
      const overviewThumbnailFile = overviewUploadResult.successful.find(
        (f) => f.meta.assetType === AssetType.THUMBNAIL,
      )?.meta;

      data.assets = [
        {
          title: EAssetTitle.overview,
          fileId: overviewFile.remoteFileId,
          uploadTime: overviewFile.uploadTime,
          forceCreate: true,
        },
        {
          title: EAssetTitle.thumbnail,
          fileId: overviewThumbnailFile.remoteFileId,
          uploadTime: overviewThumbnailFile.uploadTime,
          forceCreate: true,
        },
      ];
    }

    if (fpAreaUploadResult) {
      const fpAreaFile = fpAreaUploadResult.successful.find(
        (f) => f.meta.assetType === AssetType.ORIGINAL,
      )?.meta;

      data.assets = [
        ...data.assets,
        {
          title: EAssetTitle.FP_AREA,
          fileId: fpAreaFile.remoteFileId,
          uploadTime: fpAreaFile.uploadTime,
          forceCreate: true,
        },
      ];
    }

    if (data.assets.length === 0) {
      return Promise.resolve('No assets to update');
    }

    const url = `/skus/${sku?.id}/assets/bulk`;

    return axios.post<ResponseData<any>>(url, data);
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...RESET_VALUES,
      overviewFileId: overviewAsset?.file.id || '',
      fpAreaFileId: fpAreaAsset?.file.id || '',
    },
    validationSchema: Yup.object().shape({
      overviewFileId: Yup.string().required('Overview image is required'),
      fpAreaFileId: Yup.string().required('Fingerprint area image is required'),
    }),
    onSubmit: async (values, helpers) => {
      const { submit, ...vals } = values;

      updateMutation.mutate(vals, {
        onSuccess: () => {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);

          toast.success('SKU images were updated');
          queryClient.invalidateQueries('sku');
          onClose();
        },
        onError: (err: AxiosError) => {
          logger('[SKU/UPDATE] Error', err);
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
          helpers.setErrors({ submit: (err?.response?.data as any)?.error?.message });
        },
      });
    },
  });

  useEffect(() => {
    overviewUppy.on('file-added', (file) => {
      formik.setFieldValue('overviewFileId', file.id);
    });
    overviewUppy.on('file-removed', () => {
      formik.setFieldValue('overviewFileId', '');
    });
  }, [overviewUppy]);

  useEffect(() => {
    fpAreaUppy.on('file-added', (file) => {
      formik.setFieldValue('fpAreaFileId', file.id);
    });
    fpAreaUppy.on('file-removed', () => {
      formik.setFieldValue('fpAreaFileId', '');
    });
  }, [fpAreaUppy]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
        },
      }}
      {...other}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit SKU Images</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {/* Wallet picture uploader */}
              <Typography variant="subtitle2">Overview Image *</Typography>
              <WalletUploader {...overviewUploaderProps} />
              {Boolean(
                (formik.touched.overviewFileId && formik.errors.overviewFileId) ||
                  overviewUploaderError,
              ) && (
                <FormHelperText error>
                  {formik.errors.overviewFileId || overviewUploaderError}
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Fingerprint area uploader */}
              <Typography variant="subtitle2">Fingerprint Area Navigation *</Typography>
              <FpAreaUploader {...fpAreaUploaderProps} />
              {Boolean(
                (formik.touched.fpAreaFileId && formik.errors.fpAreaFileId) || fpAreaUploaderError,
              ) && (
                <FormHelperText error>
                  {formik.errors.fpAreaFileId || fpAreaUploaderError}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              formik.isSubmitting ||
              isOverviewUploaderLoading ||
              isFpAreaUploaderLoading ||
              updateMutation.isLoading
            }
            color="primary"
            onClick={onClose}
            variant="text"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={
              formik.isSubmitting ||
              isOverviewUploaderLoading ||
              isFpAreaUploaderLoading ||
              updateMutation.isLoading
            }
            color="primary"
            type="submit"
            size="large"
            variant="contained"
          >
            Update Images
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SKUEditAssetsDialog.defaultProps = {
  open: false,
};

SKUEditAssetsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sku: PropTypes.any,
};
