import { FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { Item } from '../../../../types/item';
import { useDialog } from '../../../../hooks/use-dialog';
import { ImageDialog } from '../../../../components/image-dialog';
import { getAssetUrl } from '../../../../utils/assets-helper';
import { ImageThumbnail } from '../../../../components/image-thumbnail';
import { EAssetTitle } from '../../../../types/asset';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';

interface ItemAssetsCardProps {
  item: Item;
  location?: 'event' | 'other';
}

export const ItemAssetsCard: FC<ItemAssetsCardProps> = (props) => {
  const { item, ...other } = props;
  const [openFrontImage, onFrontImageOpen, onFrontImageClose] = useDialog();
  const [openBackImage, onBackImageOpen, onBackImageClose] = useDialog();

  const displayUnavailable = useMemo(() => !item.assets || item.assets.length === 0, [item.assets]);
  const displayWide = useMemo(() => {
    if (item.sku?.publicMetadata?.width && item.sku?.publicMetadata?.height) {
      const { width: w, height: h } = item.sku.publicMetadata;
      return parseFloat(w) / parseFloat(h) <= 1 ? 'lg' : false;
    }

    return false;
  }, [item.sku]);

  return (
    <Card variant="outlined" {...other}>
      <CardHeader title="Item Images" />
      <Divider />
      <CardContent>
        {displayUnavailable ? (
          <ResourceUnavailable
            text="No images available at this moment."
            sx={{
              flexGrow: 1,
            }}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <ImageThumbnail
                src={getAssetUrl(item, [EAssetTitle.FRONT_PREVIEW, EAssetTitle.FRONT_IMAGE])}
                alt="Front Side"
                size="auto"
                onClick={onFrontImageOpen}
              />
              <ImageDialog
                open={openFrontImage}
                onClose={onFrontImageClose}
                title="Front Side"
                src={getAssetUrl(item, EAssetTitle.FRONT_IMAGE)}
                maxWidth={displayWide}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ImageThumbnail
                src={getAssetUrl(item, [EAssetTitle.BACK_PREVIEW, EAssetTitle.BACK_IMAGE])}
                alt="Back Side"
                size="auto"
                onClick={onBackImageOpen}
              />
              <ImageDialog
                open={openBackImage}
                onClose={onBackImageClose}
                title="Back Side"
                src={getAssetUrl(item, EAssetTitle.BACK_IMAGE)}
                maxWidth={displayWide}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

ItemAssetsCard.propTypes = {
  // @ts-ignore
  item: PropTypes.object.isRequired,
};
