import { FC } from 'react';
import PropTypes from 'prop-types';
import { SKU } from '../../types/sku';
import { ETemplateType } from '../../templates/types';
import { SKUAssetsCard as SKUAssetsCardDefault } from '../../templates/default/components/sku/sku-card-assets';
import { SKUAssetsCard as SKUAssetsCardAuthentique } from '../../templates/authentique/components/sku/sku-card-assets';

interface SKUAssetsCardProps {
  sku: SKU;
  editable?: boolean;
  location?: 'event' | 'other';
}

export const SKUAssetsCard: FC<SKUAssetsCardProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <SKUAssetsCardDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <SKUAssetsCardAuthentique {...props} />;
  }
};

SKUAssetsCard.propTypes = {
  // @ts-ignore
  sku: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  location: PropTypes.oneOf(['event', 'other']),
};
