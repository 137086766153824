import { FC, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { useMediaQuery, Theme, Box, Button } from '@material-ui/core';
import ReactJson from 'react-json-view';
import { PropertyListItem } from './property-list-item';
import { Download as DownloadIcon } from '../icons/download';

interface PropertyListItemMetadataProps {
  label: string;
  metadata?: any;
  mlLogFilename?: string;
}

export const PropertyListItemMetadata: FC<PropertyListItemMetadataProps> = ({
  label,
  metadata,
  mlLogFilename,
}) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const align = mdDown ? 'vertical' : 'horizontal';

  const show = useMemo(() => Object.keys(metadata).length > 0, [metadata]);

  const [visibleMetadata, mlLog] = useMemo(() => {
    const { visualisationJob, visualisationTask, ...meta } = metadata;

    return [meta, meta.blurDebugJson];
  }, [metadata]);

  const handleDownload = useCallback(() => {
    const blob = new Blob([JSON.stringify(mlLog)], { type: 'application/json' });

    FileSaver.saveAs(blob, `${mlLogFilename || `metadata-${new Date().toISOString()}`}.json`);
  }, [mlLog, mlLogFilename]);

  const showBlurDebugJsonDevice = useMemo(
    () =>
      !['manufacturer', 'model', 'osVersion'].every((key) => Object.keys(metadata).includes(key)),
    [metadata],
  );

  if (!show) {
    return <></>;
  }

  return (
    <PropertyListItem align={align} label={label}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'space-between',
        }}
      >
        <ReactJson
          src={visibleMetadata}
          name={false}
          sortKeys
          shouldCollapse={(field) =>
            !(
              (showBlurDebugJsonDevice &&
                (field.name === 'blurDebugJson' ||
                  (field.namespace.includes('blurDebugJson') && field.name === 'device'))) ||
              !field.name
            )
          }
        />
        {mlLog && (
          <Button
            onClick={handleDownload}
            sx={{ ml: 2 }}
            variant="outlined"
            startIcon={<DownloadIcon />}
          >
            ML Log
          </Button>
        )}
      </Box>
    </PropertyListItem>
  );
};

PropertyListItemMetadata.propTypes = {
  label: PropTypes.string.isRequired,
  metadata: PropTypes.any,
  mlLogFilename: PropTypes.string,
};
